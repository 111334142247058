.ek-section {
  margin-bottom: 2.5rem;
}

.ek-section a {
  color: var(--color-accent);
  font-weight: 700;
}

.ek-section h3 {
  color: var(--blue-800);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.ek-section h4,
.ek-section h5,
.ek-section h6,
.ek-section h4 *,
.ek-section h5 *,
.ek-section h6 * {
  color: var(--blue-800);
  font-family: var(--font-header);
  font-weight: 800;
}

.ek-section ul {
  list-style: disc;
}

.ek-section ol {
  list-style: decimal;
}

.ek-section li {
  font-family: var(--font-body);
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: var(--ls-normal);
  margin-bottom: var(--spacing-2);
}

.c-text-snippet {
  padding: 0;
}

.c-code-snippet p {
  margin-bottom: 0;
}

/* Link buttons */

.c-link-button {
  background-color: var(--white);
  border: 2px solid;
  border-color: var(--color-accent);
  border-radius: 10px;
  padding: var(--spacing-3) var(--spacing-4);
  transition: 100ms ease-in-out;
}

.c-link-button .c-link-button__text {
  color: var(--color-accent) !important;
  font-family: var(--font-header);
}

.c-link-button:hover {
  background-color: var(--color-accent);
}

.c-link-button:hover .c-link-button__text {
  color: var(--white) !important;
}

/* Textareas */
.c-question-snippet {
  margin-bottom: 1.5rem;
}

.c-question-snippet__question {
  align-items: center;
  display: flex;
  font-weight: 600;
}

.c-question-snippet__major {
  align-items: center;
  background-color: var(--blue-800);
  border-radius: 50%;
  color: var(--white) !important;
  display: flex;
  height: var(--spacing-8);
  justify-content: center;
  margin-right: 1rem;
  min-width: var(--spacing-8);
  padding-top: var(--spacing-1);
  width: var(--spacing-8);
}

.c-input-field__input {
  background-color: var(--white);
  border: 3px solid transparent;
  border-radius: 8px;
  font-family: var(--font-body);
  font-size: 1rem;
  outline: none;
  padding: 0.85rem;
  transition: 250ms ease-in-out;
}

.c-input-field__input--textarea {
  min-height: 6rem !important;
}

.c-input-field__input:focus {
  border-color: var(--color-accent);
}

/* Reveal accordions (with icons) */
.c-event-trigger--text-and-icon {
  background-color: var(--green-500);
  border-radius: 8px;
  cursor: pointer;
  color: var(--white) !important;
  font-family: var(--font-header);
  padding: var(--spacing-2) var(--spacing-4);
  text-align: left;
  width: 100%;
}

.c-event-trigger__details {
  margin-bottom: 0;
}

.c-event-trigger--text-and-icon[aria-expanded='true'] {
  background-color: var(--green-400);
}

.c-event-trigger--text-and-icon .c-event-trigger__title {
  color: var(--white) !important;
  font-family: var(--font-header);
  font-weight: 700;
  margin: 0;
}

.c-accordion__details {
  background-color: var(--white);
  border-radius: 8px;
  padding: var(--spacing-4);
}

.c-event-trigger__icon-container {
  margin: 0;
}

.c-event-trigger__icon {
  background-color: var(--white);
  border-radius: 50%;
  color: var(--color-accent);
}

/* Activity icons */
.ek-aim,
.ek-checklist,
.ek-activity {
  align-items: flex-start;
  display: flex;
  position: relative;
}

.ek-aim .c-snippet__icon,
.ek-checklist .c-snippet__icon,
.ek-activity .c-snippet__icon {
  align-items: center;
  background-color: var(--blue-800);
  border-radius: 8px;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  margin-right: var(--spacing-6);
  min-width: 3.5rem;
  padding: var(--spacing-3);
  width: 3.5rem;
}

.ek-aim .c-snippet__icon img,
.ek-checklist .c-snippet__icon img,
.ek-activity .c-snippet__icon img {
  height: 100%;
  width: 100%;
}

.ek-aim h5,
.ek-checklist h5,
.ek-activity h5 {
  color: var(--blue-800);
  font-family: var(--font-header);
  font-weight: 800;
}

/* Hint theme - Question theme - Infobox theme - Warning theme - DASS21 results normal, cop results */

.ek-warning,
.ek-info-box,
.ek-hint,
.ek-puzzle,
.ek-question,
.ek-cope-result {
  align-items: center;
  background-color: var(--white);
  border: 3px solid;
  border-radius: 0 10px 10px 10px;
  display: flex;
  margin: var(--spacing-12) 0 0 0;
  padding: var(--spacing-8);
  position: relative;
}

.ek-info-box,
.ek-hint,
.ek-puzzle,
.ek-question {
  padding: var(--spacing-6);
}

.ek-info-box p,
.ek-hint p,
.ek-puzzle p,
.ek-warning p,
.ek-question p,
.ek-cope-result p {
  margin: 0;
}

.ek-info-box h5,
.ek-info-box h6 {
  margin-bottom: var(--spacing-4);
}

.ek-warning .c-snippet__icon,
.ek-info-box .c-snippet__icon,
.ek-hint .c-snippet__icon,
.ek-puzzle .c-snippet__icon,
.ek-question .c-snippet__icon,
.ek-cope-result .c-snippet__icon {
  align-items: center;
  background-color: var(--blue-800);
  border: 3px solid;
  border-color: var(--blue-800);
  border-radius: 10px 10px 0 0;
  display: flex;
  height: var(--spacing-12);
  justify-content: center;
  left: -3px;
  padding: var(--spacing-2);
  position: absolute;
  top: -3px;
  transform: translate(0, -100%);
  width: var(--spacing-12);
}

.ek-warning,
.ek-info-box,
.ek-cope-result {
  border-color: var(--blue-800);
}

.ek-hint,
.ek-puzzle,
.ek-cope-result {
  border-color: var(--color-accent);
}

.ek-question {
  border-color: var(--color-accent-300);
}

.ek-hint h6 {
  color: var(--color-accent);
  font-weight: 700;
  margin-bottom: var(--spacing-4);
}

.ek-hint .c-snippet__icon,
.ek-puzzle .c-snippet__icon {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
}

.ek-question .c-snippet__icon {
  background-color: var(--color-accent-300);
  border-color: var(--color-accent-300);
}

/* DASS21 results Moderate */
.ek-section-10033 .ek-warning {
  border-color: #3cb043;
}

.ek-section-10034 .ek-warning {
  border-color: #ff8300;
}

.ek-section-10033 .ek-warning .c-snippet__icon {
  background-color: #3cb043;
  border-color: #3cb043;
}

.ek-section-10034 .ek-warning .c-snippet__icon {
  background-color: #ff8300;
  border-color: #ff8300;
}

/* DASS21 results Severe */
.ek-section-10035 .ek-warning {
  border-color: #e73c48;
}

.ek-section-10035 .ek-warning .c-snippet__icon {
  background-color: #e73c48;
  border-color: #e73c48;
}

/* Puzzle icon in dropdown - prechapter */
.ek-section-9939 .ek-puzzle {
  margin-bottom: var(--spacing-10);
  margin-top: var(--spacing-4);
}

@media (min-width: 64em) {
  .ek-warning,
  .ek-info-box,
  .ek-hint,
  .ek-puzzle,
  .ek-question,
  .ek-cope-result {
    margin: 0 0 0 var(--spacing-12);
  }

  .ek-warning .c-snippet__icon,
  .ek-info-box .c-snippet__icon,
  .ek-hint .c-snippet__icon,
  .ek-puzzle .c-snippet__icon,
  .ek-question .c-snippet__icon,
  .ek-cope-result .c-snippet__icon {
    border-radius: 10px 0 0 10px;
    padding: var(--spacing-2);
    transform: translate(-100%, 0);
  }
}

/* Thermometer theme */
.ek-low-thermometer,
.ek-severe-thermometer {
  align-items: center;
  background-color: var(--white);
  border: 3px solid;
  border-radius: 0 10px 10px 10px;
  display: flex;
  justify-content: center;
  margin: var(--spacing-12) 0 0 0;
  padding: var(--spacing-8);
  position: relative;
}

.ek-low-thermometer .c-snippet__icon,
.ek-severe-thermometer .c-snippet__icon {
  align-items: center;
  border: 3px solid;
  border-radius: 10px 10px 0 0;
  display: flex;
  height: var(--spacing-12);
  justify-content: center;
  left: -3px;
  max-height: var(--spacing-12);
  max-width: var(--spacing-12);
  padding: var(--spacing-2);
  position: absolute;
  top: -3px;
  transform: translate(0, -100%);
  width: var(--spacing-12);
}

.ek-low-thermometer .c-snippet__icon {
  background-color: var(--blue-900);
  border-color: var(--blue-900);
}

.ek-low-thermometer .c-snippet__icon img,
.ek-severe-thermometer .c-snippet__icon img {
  max-height: 100%;
}

.ek-low-thermometer {
  border-color: var(--blue-900);
}

.ek-severe-thermometer {
  border-color: #a03048;
}

.ek-severe-thermometer .c-snippet__icon {
  background-color: #a03048;
  border-color: #a03048;
}

@media (min-width: 64em) {
  .ek-low-thermometer,
  .ek-severe-thermometer {
    margin: 0 0 0 var(--spacing-12);
  }

  .ek-low-thermometer .c-snippet__icon,
  .ek-severe-thermometer .c-snippet__icon {
    border-radius: 10px 0 0 10px;
    padding: var(--spacing-2);
    transform: translate(-100%, 0);
  }
}

/* Card theme  */

.ek-card {
  background-color: var(--white);
  border: 3px solid #c9eeed;
  border-radius: 24px;
  padding: var(--spacing-8) var(--spacing-6);
  transition: 200ms ease-in-out;
}

/* .ek-card * {
  color: var(--black) !important;
  font-family: var(--font-body) !important;
  font-weight: 300 !important;
  text-align: left !important;
} */

.ek-card:hover {
  border-color: var(--blue-800);
  box-shadow: rgb(9 30 66 / 31%) 0 0 1px, rgb(9 30 66 / 25%) 0 20px 32px -8px;
  transform: scale(1.05);
}

@media (min-width: 64em) {
  .ek-card {
    min-height: 17rem;
  }
}

.ek-section-9389 .l-layout--even-split {
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
}

/* Carousel + quote snippet */

.flicking-pagination-bullet-active {
  background-color: var(--white);
}

.flicking-pagination-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flicking-pagination-bullet {
  background-color: var(--white);
  border: 2px solid var(--color-accent);
  height: 1rem;
  width: 1rem;
}

.flicking-arrow-next:after,
.flicking-arrow-next:before,
.flicking-arrow-prev:after,
.flicking-arrow-prev:before {
  background-color: var(--color-accent) !important;
}

.flicking-arrow-next,
.flicking-arrow-prev {
  display: none;
}

.c-slider-snippet__container {
  background-color: var(--white);
  border-radius: 10px;
  padding: var(--spacing-6);
}

.c-slider-snippet__container .flicking-pagination-bullets .flicking-pagination-bullet {
  padding: 0;
}

.c-slider-snippet__container .flicking-pagination-bullets {
  margin-top: var(--spacing-4);
}

.c-slider-snippet__container .flicking-pagination-bullets span {
  padding: 0 var(--spacing-2);
}

.c-slider-snippet__container .flicking-pagination-bullets .flicking-pagination-bullet::before {
  background-color: var(--spacing-4);
  border: 2px solid #ade2e0;
  height: var(--spacing-4);
  width: var(--spacing-4);
}

.c-slider-snippet__container .flicking-pagination-bullets .flicking-pagination-bullet-active {
  border: 2px solid var(--color-accent);
  border-radius: 50px;
  height: 1.25rem;
  padding: 0.13rem;
  position: relative;
  width: 2.25rem;
}

.c-slider-snippet__container .flicking-pagination-bullets .flicking-pagination-bullet-active::before {
  background-color: var(--color-accent);
  border: none;
  border-radius: 50px;
  content: '';
  display: block;
  height: 100%;
  padding: 0;
  width: 100%;
}

.c-quote {
  text-align: center;
}

.c-quote__content {
  font-style: normal;
}

.c-quote__speech-marks--open {
  background-color: var(--color-accent);
  color: var(--white);
  display: inline-flex;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: var(--spacing-6);
}

.c-quote__speech-marks--close {
  display: none;
}

.c-quote__author {
  font-weight: 500;
  font-style: initial;
  font-size: 1rem;
  margin-bottom: var(--spacing-4);
  margin-top: var(--spacing-8);
  text-align: center;
}

.netfront-ui-external-brainhubeu .c-event-trigger__icon {
  display: none;
}

/* Checkboxes / Breif cope radios */

.ek-section-9370 abbr,
.ek-section-10000 abbr,
.ek-section-10003 abbr,
.ek-section-10005 abbr,
.ek-section-10007 abbr {
  display: none;
}

.c-question-snippet fieldset,
.ek-section-10000 .c-question-snippet fieldset,
.ek-section-10003 .c-question-snippet fieldset,
.ek-section-10005 .c-question-snippet fieldset,
.ek-section-10007 .c-question-snippet fieldset {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
}

.ek-section-10000 .c-radio-button .c-label,
.ek-section-10003 .c-radio-button .c-label,
.ek-section-10005 .c-radio-button .c-label,
.ek-section-10007 .c-radio-button .c-label {
  pointer-events: none;
}

.c-checkbox .c-label,
.ek-section-10000 .c-radio-button .c-label,
.ek-section-10003 .c-radio-button .c-label,
.ek-section-10005 .c-radio-button .c-label,
.ek-section-10007 .c-radio-button .c-label {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid #000;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: center;
  margin-right: var(--spacing-2);
  padding: var(--spacing-2) var(--spacing-4);
  position: relative;
}

.c-checkbox__icon-container,
.ek-section-10000 .c-radio-button__icon,
.ek-section-10003 .c-radio-button__icon,
.ek-section-10005 .c-radio-button__icon,
.ek-section-10007 .c-radio-button__icon {
  display: none;
}

.c-checkbox .c-label__text,
.ek-section-10000 .c-radio-button .c-label__text,
.ek-section-10003 .c-radio-button .c-label__text,
.ek-section-10005 .c-radio-button .c-label__text,
.ek-section-10007 .c-radio-button .c-label__text {
  margin: 2px 0 0 0;
}

.c-checkbox input:checked + .c-checkbox__label,
.ek-section-10000 input:checked + .c-label,
.ek-section-10003 input:checked + .c-label,
.ek-section-10005 input:checked + .c-label,
.ek-section-10007 input:checked + .c-label {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
  color: var(--white);
}

.ek-section-10000 .c-label,
.ek-section-10003 .c-label,
.ek-section-10005 .c-label,
.ek-section-10007 .c-label {
  cursor: pointer;
}

.ek-section-10000 .c-radio-button__item,
.ek-section-10003 .c-radio-button__item,
.ek-section-10005 .c-radio-button__item,
.ek-section-10007 .c-radio-button__item {
  width: 100% !important;
}

.ek-section-10000 .c-label::before,
.ek-section-10003 .c-label::before,
.ek-section-10005 .c-label::before,
.ek-section-10007 .c-label::before {
  display: none;
}

.ek-section-10000 .c-radio-button__input,
.ek-section-10003 .c-radio-button__input,
.ek-section-10005 .c-radio-button__input,
.ek-section-10007 .c-radio-button__input {
  display: block;
  position: absolute;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.ek-section-10000 .c-snippet,
.ek-section-10003 .c-snippet,
.ek-section-10005 .c-snippet,
.ek-section-10007 .c-snippet {
  margin-bottom: 1.5rem;
}

/* Radio buttons */

.c-radio-button {
  position: relative;
  margin-bottom: 1rem;
}

.c-radio-button .c-label {
  flex-direction: row;
}

.c-radio-button__input {
  display: none;
  margin: 0;
}

.c-radio-button__icon {
  display: none;
  border: 3px solid var(--color-accent-300);
}

.c-radio-button .c-radio-button__input:checked + .c-label .c-radio-button__icon {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
}

.c-radio-button__icon::after {
  background-color: var(--color-accent);
  display: none;
}

.c-radio-button__item~label:before {
  border: 3px solid var(--color-accent-300);
}

.c-radio-button__item:checked~label:before {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
}

.c-radio-button .c-label__text {
  margin: 0 1.5rem 0 0.5rem;
}

/* Brain cancer pathway radios */

.ek-section-9985 .c-radio-button {
  width: 100%;
}

.ek-section-9985 abbr {
  display: none;
}

.ek-section-9985 fieldset {
  align-items: center;
  justify-content: center;
}

.ek-section-9985 .c-radio-button .c-label {
  align-items: center;
  background-color: var(--white);
  border: 1px solid #000;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  height: 4rem;
  justify-content: center;
  margin-right: var(--spacing-2);
  padding: 0 var(--spacing-8);
  position: relative;
  text-align: center;
}

.ek-section-9985 .c-radio-button .c-radio-button__icon,
.ek-section-9985 .c-radio-button .c-radio-button__input {
  display: none;
}

.ek-section-9985 .c-radio-button .c-label__text {
  margin: 0;
  white-space: nowrap;
}

.ek-section-9985 .c-radio-button .c-radio-button__input:checked + .c-label {
  background-color: var(--blue-800);
  border-color: var(--blue-800);
  color: var(--white);
}

.ek-section-9985 .c-radio-button__item ~ label::before {
  display: none;
}

.ek-section-9985 .c-radio-button__item:checked ~ label {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
  color: var(--white);
}

@media (min-width: 72em) {
  .ek-section-9985 .c-radio-button {
    margin: var(--spacing-2) 0;
    width: 50%;
  }

  .ek-section-9985 .c-radio-button .c-label {
    height: 4rem;
  }
}

/* home page sliders */

.c-intro-slider .c-quote__speech-marks,
.c-features-slider .c-quote__speech-marks {
  display: none;
}

/* Distress thermometer range slider */
/* .ek-section-9208 */

.c-range-slider__thumb {
  background-color: #fff !important;
  border-radius: 50%;
  box-shadow: rgb(9 30 66 / 31%) 0 0 4px, rgb(9 30 66 / 25%) 0 20px 32px -8px;
  cursor: pointer;
  height: 2rem !important;
  outline: none;
  position: absolute;
  top: 0;
  width: 2rem !important;
  z-index: 2;
}

.c-range-slider__track {
  background: linear-gradient(to right, #001748, #e83c48);
  border-radius: 50px;
  height: 1rem !important;
  position: relative;
  margin-top: var(--spacing-4);
  width: 100%;
}

.c-range-slider__value {
  box-shadow: rgb(9 30 66 / 31%) 0 0 4px, rgb(9 30 66 / 25%) 0 20px 32px -8px;
  background-color: var(--white);
  border-radius: 50%;
  height: var(--spacing-6);
  width: var(--spacing-6);
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -5px;
  transform: translate(-50%, -100%);
  left: 50%;
  font-weight: 600;
  font-size: 0.85rem;
}

.ek-section-9208 .ek-text-snippet .c-dompurify-sanitized-html-content,
.ek-section-1003 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-4);
  color: var(--blue-800);
  font-family: var(--font-header);
  font-weight: 800;
}

.ek-section-10031 .c-dompurify-sanitized-html-content,
.ek-section-9931 .c-dompurify-sanitized-html-content,
.ek-section-9962 .c-dompurify-sanitized-html-content,
.ek-section-9963 .c-dompurify-sanitized-html-content,
.ek-section-9964 .c-dompurify-sanitized-html-content,
.ek-section-9965 .c-dompurify-sanitized-html-content {
  align-items: center;
  display: flex;
}

.c-question-snippet .c-dompurify-sanitized-html-content {
  align-items: center;
  display: flex;
}

.c-range-slider__mark::before {
  content: '';
  background-color: #c7ccd8;
  display: block;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 100%;
  transform: translateY(50%);
  visibility: visible;
  width: 1px;
}

.c-range-slider__mark {
  position: relative;
  visibility: hidden;
}

.c-range-slider__mark:first-of-type,
.c-range-slider__mark:last-of-type {
  color: var(--blue-800);
  font-family: var(--font-header);
  font-weight: 800;
  margin-top: var(--spacing-10);
  visibility: visible;
}

.c-range-slider__mark:first-of-type::before,
.c-range-slider__mark:last-of-type::before {
  display: none;
}

/* DASS21 form */
.ek-section-10031 abbr {
  display: none;
}

.ek-section-10031 .c-radio-button {
  position: relative;
}

.ek-section-10031 .c-label__text {
  color: var(--blue-900);
  font-family: var(--font-header);
  font-weight: 700;
  margin: 0;
}

.ek-section-10031 .c-label {
  cursor: pointer;
}

.ek-section-10031 .c-radio-button__input {
  display: block;
  opacity: 0;
}

.ek-section-10031 .c-snippet:nth-child(even) .c-question-snippet {
  background-color: #fff;
}

.ek-section-10031 fieldset {
  flex-direction: column;
  margin: var(--spacing-4) 0 0 var(--spacing-4);
}

.ek-section-10031 .c-radio-button__icon {
  margin-right: var(--spacing-2);
}

.ek-section-10031 .c-question-snippet {
  padding: var(--spacing-2) var(--spacing-4);
}

@media (min-width: 74em) {
  .ek-section-10031 fieldset {
    flex-direction: row;
    margin: 0;
  }

  .ek-section-10031 .c-radio-button__icon {
    margin-right: 0;
  }

  .ek-section-10031 .c-radio-button .c-label {
    flex-direction: column-reverse;
    gap: .5rem;
  }

  .ek-section-10031 .c-question-snippet {
    display: flex;
    margin-bottom: 0;
  }

  .ek-section-10031 .c-radio-button__input {
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
  }

  .ek-section-10031 .c-question-snippet__question {
    padding-right: var(--spacing-4);
    width: 40%;
  }

  .ek-section-10031 fieldset {
    flex: 1;
    justify-content: space-between;
  }

  .ek-section-10031 .c-label .h-spacing-small {
    visibility: hidden;
  }

  .ek-section-10031 .qa-content-snippet-id-20823 .c-label .h-spacing-small {
    margin-bottom: var(--spacing-6);
    visibility: visible;
  }

  .ek-section-10031 .c-question-snippet__question p {
    margin-bottom: 0;
  }

  .ek-section-10031 .c-question-snippet__major {
    margin-bottom: 0;
  }

  .ek-section-10031 #question-snippet-id-20823 {
    margin-top: var(--spacing-8);
  }
}

/* Brief COPE Quiz results */

.ek-section-10285 .qa-content-snippet-id-21786 {
  margin-bottom: var(--spacing-10);
}

/* Stress cycle diagrams/forms */
.ek-section-10037 .ek-text-snippet {
  background-color: var(--white);
  border-bottom: 6px solid;
  border-radius: 10px 10px 0 0;
  margin-bottom: var(--spacing-8);
  padding: var(--spacing-8);
}

.ek-section-10037 .qa-content-snippet-id-20837 .ek-text-snippet {
  border-color: var(--color-accent);
}

.ek-section-10037 .qa-content-snippet-id-20838 .ek-text-snippet {
  border-color: var(--blue-800);
}

.ek-section-10037 .qa-content-snippet-id-20839 .ek-text-snippet {
  border-color: var(--red-700);
}

.ek-section-10047 .c-question-snippet__question * {
  font-family: var(--font-header);
  font-size: 1.35rem;
  font-weight: 800;
}

.ek-section-10037 .qa-content-snippet-id-20837 .ek-text-snippet h3,
.ek-section-10037 .qa-content-snippet-id-20837 .ek-text-snippet h5,
.ek-section-10047 #question-snippet-id-20942 {
  color: var(--color-accent);
}

.ek-section-10037 .qa-content-snippet-id-20838 .ek-text-snippet h3,
.ek-section-10037 .qa-content-snippet-id-20838 .ek-text-snippet h5,
.ek-section-10047 #question-snippet-id-20943 {
  color: var(--blue-800);
}

.ek-section-10037 .qa-content-snippet-id-20839 .ek-text-snippet h3,
.ek-section-10037 .qa-content-snippet-id-20839 .ek-text-snippet h5,
.ek-section-10047 #question-snippet-id-20944 {
  color: var(--red-700);
}

@media (min-width: 72em) {
  .ek-section-10037 .ek-text-snippet,
  .ek-section-10048 .c-question-snippet {
    margin-left: var(--spacing-8);
  }
}

/* How much do I demand of myself section - prechapter */
.ek-section-9995 img {
  display: block;
  margin: 0 auto;
  max-width: 450px;
}

@media (min-width: 72em) {
  .ek-section-9995 .c-input-field__input {
    margin-left: var(--spacing-10);
  }
}

/* Senses cards - chapter 2 */
.ek-senses-card .l-layout__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ek-senses-card h4 {
  color: var(--blue-900);
  font-weight: 800;
  margin-bottom: var(--spacing-2);
}

.ek-senses-card h5 {
  color: var(--color-accent);
}

@media (min-width: 72em) {
  .ek-senses-card .ek-text-snippet {
    padding-left: var(--spacing-4);
  }
}

/* Management cards - chapter 2 */
.ek-management-card {
  background-color: var(--white);
  border-radius: 10px;
  padding: var(--spacing-8);
}

.ek-management-card .l-layout__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ek-management-card h4 {
  color: var(--blue-800);
  font-weight: 800;
  margin-bottom: var(--spacing-2);
}

.ek-management-card img {
  display: block;
  margin: 0 auto;
  max-width: 150px;
  min-width: auto;
}

@media (min-width: 72em) {
  .ek-management-card img {
    margin: 0;
  }
}

/* Grief card theme */
.ek-grief-card {
  background-color: var(--white);
  border-radius: 10px;
  padding: var(--spacing-8);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.ek-grief-card .ek-text-snippet {
  color: var(--blue-800);
  font-weight: 700;
  margin-bottom: var(--spacing-6);
}
.ek-grief-card img {
  display: block;
  margin: 0 auto;
  max-width: 180px;
  min-width: auto;
}

@media (min-width: 72em) {
  .ek-grief-card {
    min-height: 24rem;
  }
}

/* Coping approaches - chapter 1 */

.ek-section-9502 h4 {
  color: var(--color-accent);
  font-size: 1.2rem;
  margin-bottom: var(--spacing-3);
}

.ek-section-9502 .l-layout__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 72em) {
  .ek-section-9502 .ek-container-12312 {
    padding-left: var(--spacing-4);
  }
}

/* Red flag theme/icon - Green flag/icon - self -care */
.ek-self-care,
.ek-red-flag,
.ek-green-flag,
.ek-spoon-icon {
  align-items: center;
  display: flex;
  margin-bottom: var(--spacing-4);
}

.ek-self-care .c-snippet__icon,
.ek-red-flag .c-snippet__icon,
.ek-green-flag .c-snippet__icon,
.ek-spoon-icon .c-snippet__icon {
  margin-right: var(--spacing-4);
}

.ek-green-flag .c-snippet__icon {
  min-width: var(--spacing-12);
}

.ek-self-care p,
.ek-red-flag p,
.ek-green-flag p,
.ek-spoon-icon p {
  color: var(--blue-900);
  font-weight: 700;
  margin-bottom: 0;
}

.ek-self-care img {
  max-width: var(--spacing-16);
}

/* Vertical radio list */
.ek-vertical-radio-list .c-question-snippet fieldset {
  flex-direction: column;
}

.ek-vertical-radio-list .c-question-snippet fieldset legend {
  display: none;
}

.ek-vertical-radio-list .c-question-snippet__question {
  margin-bottom: var(--spacing-2);
}

.ek-vertical-radio-list .c-radio-button .c-label {
  cursor: pointer;
}

/* Vertical checkbox list (h-hide-visually causes issues when more than one form is on the page) */

.ek-vertical-checkbox-list .c-checkbox {
  display: flex;
  margin: 0 0 var(--spacing-6) 0;
  position: relative;
}

.ek-vertical-checkbox-list .c-checkbox + .c-checkbox {
  flex-direction: row;
}

.ek-vertical-checkbox-list .c-checkbox__input {
  display: block;
  clip-path: none;
  clip: auto;
  height: var(--g-spacing-x-large);
  opacity: 0;
  cursor: pointer;
  width: var(--g-spacing-x-large);
  z-index: 9999;
}

.ek-vertical-checkbox-list .c-checkbox .c-label {
  background-color: transparent;
  border: none;
  padding: 0;
  pointer-events: none;
}

.ek-vertical-checkbox-list .c-question-snippet fieldset {
  flex-direction: column;
  padding-left: var(--spacing-10);
}

.ek-vertical-checkbox-list .c-question-snippet__question {
  margin-bottom: var(--spacing-2);
}

.ek-vertical-checkbox-list .c-question-snippet fieldset legend {
  display: none;
}

.ek-vertical-checkbox-list .c-checkbox__icon-container {
  border: 3px solid var(--color-accent-300);
  border-radius: 5px;
  display: block;
  margin-bottom: var(--spacing-1);
}

.ek-vertical-checkbox-list .c-checkbox__icon-container svg {
  display: none;
}

.ek-vertical-checkbox-list .c-checkbox input:checked + .c-checkbox__label {
  background-color: transparent;
  border-color: transparent;
  color: var(--blue-900);
}

.ek-vertical-checkbox-list .c-checkbox__input:checked + .c-label .c-checkbox__icon-container {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
}

.c-summary-snippet * {
  cursor: default !important;
}

.c-summary-snippet .c-user-generated-response__container,
.c-summary-snippet .checkbox-item-delete-button-container {
  display: none;
}

.ek-question p:not(:last-of-type),
.ek-hint p:not(:last-of-type),
.ek-info-box p:not(:last-of-type),
.ek-warning p:not(:last-of-type),
.ek-puzzle p:not(:last-of-type) {
  margin-bottom: 1rem;
}

/* Range slider label theme */

.ek-range-slide .ek-text-snippet {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-8);
}

/* Our research page image */

.ek-section-10125 img {
  display: block;
  max-width: 34rem;
  margin: 0 auto;
  width: 100%;
}

/* This-that slider */
.ek-section-9577 .c-slider-snippet__container {
  background-color: transparent;
  background-image: url(/images/this-that-character.svg);
  background-repeat: no-repeat;
  background-position: left;
  margin: var(--spacing-8) 0;
}

.ek-section-9577 .c-slider-snippet__container .c-quote__speech-marks {
  display: none;
}

.ek-section-9577 .c-slider-snippet__container .flicking-panel {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0rem;
}

.c-this-that__card {
  background-color: var(--white);
  border: 3px solid #c9eeed;
  border-radius: 24px;
  max-width: 15rem;
  padding: var(--spacing-8) var(--spacing-6);
}

.c-this-that__card--this {
  margin-right: var(--spacing-4);
}

.c-this-that__card--that {
  border-color: var(--blue-800);
  margin-right: var(--spacing-4);
}

/* Identify phase - remove excessive form section margin: chapter-1/brain-cancer-as-a-personal-and-family-crisis/identify-your-phase */

.ek-section-10120,
.ek-section-10120 .ek-section {
  margin-bottom: 0;
}

/* Spoon theory - remove excessive form section margin: chapter-1/actively-coping-with-stress/spoon-theory-and-taking-care-of-yourself */

.ek-section-10081,
.ek-section-10081 .ek-section,
.ek-section-10081 .l-layout__item {
  margin-bottom: 0 !important;
}

/* Remove titles under videos */

.c-embed-snippet__title {
  display: none;
}

/* About card theme */

.ek-about-card {
  background-color: var(--white);
  border-radius: 10px;
  padding: var(--spacing-8);
}

.ek-about-card .l-layout__item {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ek-about-card img {
  min-width: auto;
  max-width: var(--spacing-60);
  width: 100%;
}

.ek-range-slide .c-dompurify-sanitized-html-content {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-bottom: var(--spacing-2);
}

/* Remove margin for resource dropdown section: chapter-3/reflecting-on-the-program/additional-information-resources-and-supports */

.ek-section-9882 {
  margin-bottom: -1rem;
}

/* Table animations */
.animate__fadeIn {
  opacity: 0;
  animation: fadeIn 500ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.animate__delay-2s {
  animation-delay: 2s;
}

.animate__delay-3s {
  animation-delay: 3s;
}

.animate__delay-4s {
  animation-delay: 4s;
}

.animate__delay-5s {
  animation-delay: 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Spoon theory results section */
.ek-section-10083 .ek-hint {
  border-color: var(--red-800) !important;
}

.ek-section-10083 .ek-hint .c-snippet__icon {
  background-color: var(--red-800);
  border-color: var(--red-800);
}

/* Add item spacing between problemsa nd worries form */
.ek-section-9370 .c-checkbox {
  position: relative;
}

.ek-section-9370 .c-checkbox .checkbox-item-delete-button-container {
  position: absolute;
}

.ek-section-9370 .c-button-icon-only {
  width: 1rem;
  height: 1rem;
  min-height: 2rem;
}

.ek-section-9370 .c-button-icon-only .h-hide-visually {
  display: none;
}

.ek-section-9370 .c-user-generated-response__discard-button svg,
.c-user-generated-response__save-button svg,
.checkbox-item-delete-button-container button svg {
  display: block !important;
}

.ek-section-9370 .c-user-generated-response__container {
  margin-top: var(--spacing-4);
}

@media print {
  .kanzi-audio-player {
    display: none !important;
  }
}

/* Dass results */

.ek-section-thermometer-extremely-severe .c-snippet__icon {
  background-color: var(--red-700);
  border-color: var(--red-700);
}

.ek-section-thermometer-extremely-severe .ek-warning {
  border-color: var(--red-700);
}

.ek-section-thermometer-severe .ek-warning {
  border-color: #ff4300;
}

.ek-section-thermometer-severe .c-snippet__icon {
  background-color: #ff4300;
  border-color: #ff4300;
}

.ek-section-thermometer-moderate .ek-warning {
  border-color: #ff8300;
}

.ek-section-thermometer-moderate .c-snippet__icon {
  background-color: #ff8300;
  border-color: #ff8300;
}

.ek-section-thermometer-mild .ek-warning {
  border-color: #369d3c;
}

.ek-section-thermometer-mild .c-snippet__icon {
  background-color: #369d3c;
  border-color: #369d3c;
}

.ek-section-thermometer-normal .ek-warning {
  border-color: #3cb043;
}

.ek-section-thermometer-normal .c-snippet__icon {
  background-color: #3cb043;
  border-color: #3cb043;
}

.ek-cope-result__body {
  line-height: 1.4;
}

.ek-cope-result--blue {
  border-color: var(--blue-800);
}

.ek-cope-result--green {
  border-color: #3cb043;
}

.ek-cope-result--green .c-snippet__icon {
  background-color: #3cb043;
  border-color: #3cb043;
}

.ek-section-10016 .c-range-slider__mark {
  display: none;
}

.ek-section-10016 .ek-text-snippet {
  margin-top: 0;
}

/* chapters/chapter-3/mindfulness/exercise-1-mindfulness-for-a-healthy-mood */
.ek-section-10291 .ek-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ek-section-10291 img {
  max-width: 18rem;
}

/* chapters/chapter-3/mindfulness/alternative-therapies */
.ek-section-10017 .l-layout__item {
  margin-top: -1.5rem;
}

/* Hide brain cancer pathway question text */
.ek-section-9985 .c-question-snippet__question {
  display: none;
}

/* Mindfulness sliders */
.ek-section-10016 .c-range-slider__track {
  background: linear-gradient(90deg, #001748, #3cb043);
}

/* Publish form styles */
.ek-section-9409,
.ek-section-9409 .l-layout__item,
.ek-section-9409 .c-question-snippet {
  margin-bottom: 0 !important;
}

.ek-section-10337 {
  margin-bottom: var(--spacing-16);
}

@media print {
  .c-snippet {
    page-break-inside: avoid !important;
  }
}
