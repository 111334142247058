.c-directory__results {
    margin-bottom: var(--g-spacing);
    padding: 0 var(--g-spacing-2x-large);
    width: 100%;
}

.c-directory__results-list {
    background-color: var(--g-color-white);
    border-radius: var(--g-border-radius-medium);
    height: 40rem;
    margin: 0;
    padding: var(--g-spacing);
    overflow-y: auto;
}

@media screen and (min-width: 48em) {
   .c-directory__results-list {
        height: 50rem;
    }
    
    .c-directory__results {
        width: 33.333%;
        margin-bottom: 0;
    }   
}