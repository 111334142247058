/* Backgrounds */

.bg-accent-300 {
  background-color: var(--color-accent-300);
}

.bg-white {
  background-color: var(--white);
}

.bg-grey-50 {
  background-color: var(--grey-50);
}

.bg-grey-100 {
  background-color: var(--grey-100);
}

.bg-grey-200 {
  background-color: var(--grey-200);
}

.bg-blue-800 {
  background-color: var(--blue-800);
}

.bg-blue-900 {
  background-color: var(--blue-900);
}

.bg-red-700 {
  background-color: var(--red-700);
}

.bg-red-800 {
  background-color: var(--red-800);
}

.bg-red-900 {
  background-color: var(--red-900);
}

.bg-accent {
  background-color: var(--color-accent);
}

.bg-accent-300 {
  background-color: var(--color-accent-300);
}

.bg-accent-400 {
  background-color: var(--color-accent-400);
}

.bg-green-500 {
  background-color: var(--green-500);
}

.bg-transparent {
  background-color: transparent;
}

.bg-hero {
  background-image: url(/images/hero-banner-image.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 20rem;
  width: 100%;
}

.bg-white-opacity {
  background-color: rgba(255, 255, 255, 0.7);
}

/* Colors */

.color-white {
  color: var(--white);
}

.color-black {
  color: var(--black);
}

.color-blue-700 {
  color: var(--blue-700);
}

.color-blue-800 {
  color: var(--blue-800);
}

.color-blue-900 {
  color: var(--blue-900);
}

.color-accent {
  color: var(--color-accent);
}

.color-grey-200 {
  color: var(--grey-200);
}

.color-grey-300 {
  color: var(--grey-300);
}

.color-red-700 {
  color: var(--red-700);
}

.color-red-800 {
  color: var(--red-800);
}

.color-green-900 {
  color: var(--green-900);
}

/* Displays */

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.none {
  display: none;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-1 {
  flex: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-gap-4 {
  gap: var(--spacing-4);
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.gap-1 {
  gap: .125rem;
}

.gap-2 {
  gap: .5rem;
}


.gap-4 {
  gap: 1rem;
}

/* Positions */
.float-right {
  float: right;
}
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.-right-2 {
  right: calc(-1 * var(--spacing-2));
}

.top-0 {
  top: 0;
}

.top-half {
  top: 50%;
}

.top-full {
  top: 100%;
}

.index-1 {
  z-index: 1;
}

.index-2 {
  z-index: 2;
}

.index-99 {
  z-index: 99;
}

.-index-1 {
  z-index: -1;
}

/* Dimensions */

.h-0-5 {
  height: var(--spacing-0-5);
}

.h-1 {
  height: var(--spacing-1);
}

.h-2 {
  height: var(--spacing-2);
}

.h-4 {
  height: var(--spacing-4);
}

.h-6 {
  height: var(--spacing-6);
}

.h-7 {
  height: var(--spacing-7);
}

.h-8 {
  height: var(--spacing-8);
}

.h-10 {
  height: var(--spacing-10);
}

.h-12 {
  height: var(--spacing-12);
}

.h-13 {
  height: var(--spacing-13);
}

.h-16 {
  height: var(--spacing-16);
}

.h-20 {
  height: var(--spacing-20);
}

.h-48 {
  height: var(--spacing-48);
}

.h-screen {
  height: 100vh;
}

.h-auto {
  height: auto;
}

.w-quarter {
  width: 25%;
}

.w-third {
  width: 33.333%;
}

.w-half {
  width: 50%;
}

.w-3\/5 {
  width: 60%;
}

.w-3\/4 {
  width: 75%;
}

.w-4\/5 {
  width: 80%;
}

.w-full {
  width: 100%;
}

.w-4 {
  width: var(--spacing-4);
}

.w-5 {
  width: var(--spacing-5);
}

.w-6 {
  width: var(--spacing-6);
}

.w-7 {
  width: var(--spacing-7);
}

.w-8 {
  width: var(--spacing-8);
}

.w-10 {
  width: var(--spacing-10);
}

.w-12 {
  width: var(--spacing-12);
}

.w-24 {
  width: var(--spacing-24);
}

.w-32 {
  width: var(--spacing-32);
}

.w-40 {
  width: var(--spacing-40);
}

.w-48 {
  width: var(--spacing-48);
}

.w-auto {
  width: auto;
}

.w-fit-content {
  width: fit-content;
}

.max-w-12 {
  max-width: var(--spacing-12);
}

.max-w-16 {
  max-width: var(--spacing-16);
}

.max-w-20 {
  max-width: var(--spacing-20);
}

.max-w-40 {
  max-width: var(--spacing-40);
}

.max-w-72 {
  max-width: var(--spacing-72);
}

.max-w-80 {
  max-width: var(--spacing-80);
}

.max-w-88 {
  max-width: var(--spacing-88);
}

.max-w-90 {
  max-width: var(--spacing-90);
}

.max-w-full {
  max-width: 100%;
}

.min-w-4 {
  min-width: var(--spacing-4);
}

.min-w-40 {
  min-width: var(--spacing-40);
}

.min-w-48 {
  min-width: var(--spacing-48);
}

.min-w-auto {
  min-width: auto;
}

.min-height-screen {
  min-height: 100vh;
}

/* Spacing */

.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: var(--spacing-2);
}

.mb-4 {
  margin-bottom: var(--spacing-4);
}

.mb-6 {
  margin-bottom: var(--spacing-6);
}

.mb-8 {
  margin-bottom: var(--spacing-8);
}

.mb-10 {
  margin-bottom: var(--spacing-10);
}

.mb-11 {
  margin-bottom: var(--spacing-11);
}

.mb-12 {
  margin-bottom: var(--spacing-12);
}

.mb-16 {
  margin-bottom: var(--spacing-16);
}

.mb-18 {
  margin-bottom: var(--spacing-18);
}

.mb-20 {
  margin-bottom: var(--spacing-20);
}

.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: var(--spacing-1);
}

.ml-2 {
  margin-left: var(--spacing-2);
}

.ml-4 {
  margin-left: var(--spacing-4);
}

.ml-8 {
  margin-left: var(--spacing-8);
}

.ml-12 {
  margin-left: var(--spacing-12);
}

.ml-24 {
  margin-left: var(--spacing-24);
}

.mr-2 {
  margin-right: var(--spacing-2);
}

.mr-4 {
  margin-right: var(--spacing-4);
}

.mr-6 {
  margin-right: var(--spacing-6);
}

.mr-12 {
  margin-right: var(--spacing-12);
}

.mr-24 {
  margin-right: var(--spacing-24);
}

.-mr-12 {
  margin-right: calc(-1 * var(--spacing-12));
}

.mt-0-5 {
  margin-top: var(--spacing-0-5);
}

.mt {
  margin-top: var(--spacing-1);
}

.mt-2 {
  margin-top: var(--spacing-2);
}

.mt-4 {
  margin-top: var(--spacing-4);
}

.mt-6 {
  margin-top: var(--spacing-6);
}

.mt-7 {
  margin-top: var(--spacing-7);
}

.mt-8 {
  margin-top: var(--spacing-8);
}

.mt-12 {
  margin-top: var(--spacing-12);
}

.mt-auto {
  margin-top: auto;
}

.mx-2 {
  margin-left: var(--spacing-2);
  margin-right: var(--spacing-2);
}

.mx-4 {
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-4);
}

.mx-8 {
  margin-left: var(--spacing-8);
  margin-right: var(--spacing-8);
}

.mx-16 {
  margin-left: var(--spacing-16);
  margin-right: var(--spacing-16);
}

.-mx-2 {
  margin-left: calc(-1 * var(--spacing-2));
  margin-right: calc(-1 * var(--spacing-2));
}

.-mx-4 {
  margin-left: calc(-1 * var(--spacing-4));
  margin-right: calc(-1 * var(--spacing-4));
}

.-mx-8 {
  margin-left: calc(-1 * var(--spacing-8));
  margin-right: calc(-1 * var(--spacing-8));
}

.my {
  margin-bottom: var(--spacing-4);
  margin-top: var(--spacing-4);
}

.my-2 {
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-2);
}

.my-6 {
  margin-bottom: var(--spacing-6);
  margin-top: var(--spacing-6);
}

.my-8 {
  margin-bottom: var(--spacing-8);
  margin-top: var(--spacing-8);
}

.my-12 {
  margin-bottom: var(--spacing-12);
  margin-top: var(--spacing-12);
}

.my-16 {
  margin-bottom: var(--spacing-16);
  margin-top: var(--spacing-16);
}

.my-24 {
  margin-bottom: var(--spacing-24);
  margin-top: var(--spacing-24);
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

/*  Padding  */

.p-0 {
  padding: 0;
}

.p-1 {
  padding: var(--spacing-1);
}

.p-2 {
  padding: var(--spacing-2);
}

.p-3 {
  padding: var(--spacing-3);
}

.p-4 {
  padding: var(--spacing-4);
}

.p-8 {
  padding: var(--spacing-8);
}

.p-10 {
  padding: var(--spacing-10);
}

.p-12 {
  padding: var(--spacing-12);
}

.p-16 {
  padding: var(--spacing-16);
}

.p-6 {
  padding: var(--spacing-6);
}

.pl-4 {
  padding-left: var(--spacing-4);
}

.pb-4 {
  padding-bottom: var(--spacing-4);
}

.pb-8 {
  padding-bottom: var(--spacing-8);
}

.pb-10 {
  padding-bottom: var(--spacing-10);
}

.pb-12 {
  padding-bottom: var(--spacing-12);
}

.pb-16 {
  padding-bottom: var(--spacing-16);
}

.pb-24 {
  padding-bottom: var(--spacing-24);
}

.pt-0-4-5 {
  padding-top: 0.45rem;
}

.pt-1 {
  padding-top: var(--spacing-1);
}

.pt-2 {
  padding-top: var(--spacing-2);
}

.pt-4 {
  padding-top: var(--spacing-4);
}

.pt-6 {
  padding-top: var(--spacing-6);
}

.pt-8 {
  padding-top: var(--spacing-8);
}

.pt-10 {
  padding-top: var(--spacing-10);
}

.pt-12 {
  padding-top: var(--spacing-12);
}

.pt-16 {
  padding-top: var(--spacing-16);
}

.pt-20 {
  padding-top: var(--spacing-20);
}

.pr-0 {
  padding-right: 0;
}

.px-2 {
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
}

.px-3 {
  padding-left: var(--spacing-3);
  padding-right: var(--spacing-3);
}

.px-4 {
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
}

.px-6 {
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-6);
}

.px-8 {
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);
}

.px-12 {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

.px-20 {
  padding-left: var(--spacing-20);
  padding-right: var(--spacing-20);
}

.px-24 {
  padding-left: var(--spacing-24);
  padding-right: var(--spacing-24);
}

.px-28 {
  padding-left: var(--spacing-28);
  padding-right: var(--spacing-28);
}

.px-32 {
  padding-left: var(--spacing-32);
  padding-right: var(--spacing-32);
}

.py-2 {
  padding-bottom: var(--spacing-2);
  padding-top: var(--spacing-2);
}

.py-3 {
  padding-bottom: var(--spacing-3);
  padding-top: var(--spacing-3);
}

.py-4 {
  padding-bottom: var(--spacing-4);
  padding-top: var(--spacing-4);
}

.py-6 {
  padding-bottom: var(--spacing-6);
  padding-top: var(--spacing-6);
}

.py-7 {
  padding-bottom: var(--spacing-7);
  padding-top: var(--spacing-7);
}

.py-8 {
  padding-bottom: var(--spacing-8);
  padding-top: var(--spacing-8);
}

.py-12 {
  padding-bottom: var(--spacing-12);
  padding-top: var(--spacing-12);
}

.py-16 {
  padding-bottom: var(--spacing-16);
  padding-top: var(--spacing-16);
}

/* Fonts */
.font-header {
  font-family: var(--font-header);
}

.font-body {
  font-family: var(--font-body);
}

.size-2 {
  font-size: var(--spacing-2);
}

.size-3 {
  font-size: var(--spacing-3);
}

.size-4 {
  font-size: var(--spacing-4);
}

.size-5 {
  font-size: var(--spacing-5);
}

.size-16 {
  font-size: var(--spacing-16);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.weight-500 {
  font-weight: 500;
}

.weight-700 {
  font-weight: 700;
}

.weight-800 {
  font-weight: 800;
}

.line-inherit {
  line-height: inherit;
}

.list-disc {
  list-style: disc;
}

/* Borders */

.border-none {
  border: none;
}

.border {
  border: 1px solid;
}

.border-2 {
  border: 2px solid;
}

.border-3 {
  border: 3px solid;
}

.border-5 {
  border: 5px solid;
}

.border-b {
  border-bottom: 1px solid;
}

.border-l {
  border-left: 1px solid;
}

.border-l-2 {
  border-left: 2px solid;
}

.border-t {
  border-top: 1px solid;
}

.border-t-2 {
  border-top: 2px solid;
}

.border-transparent {
  border-color: transparent;
}

.border-accent {
  border-color: var(--color-accent);
}

.border-accent-300 {
  border-color: var(--color-accent-300);
}

.border-blue-800 {
  border-color: var(--blue-800);
}

.border-grey-50 {
  border-color: var(--grey-50);
}

.border-grey-100 {
  border-color: var(--grey-100);
}

.border-red-800 {
  border-color: var(--red-800);
}

.radius {
  border-radius: 10px;
}

.radius-left {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.radius-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.radius-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.radius-bottom-0 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.radius-right-0 {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.radius-tl-none {
  border-top-left-radius: 0;
}

.radius-full {
  border-radius: 50px;
}

.radius-circle {
  border-radius: 50%;
}

.radius-0 {
  border-radius: 0;
}

/* Shadows */

.shadow {
  box-shadow: 0 -2px 17px rgb(0 0 0 / 6%);
}

.shadow-lg {
  box-shadow: 0 8px 20px rgb(0 0 0 / 6%);
}

/* Transforms */
.-translate-x-100 {
  transform: translateX(-100%);
}

.translate-x-0 {
  transform: translateX(0);
}

.translate-x-100 {
  transform: translateX(100%);
}

.-translate-y-50 {
  transform: translateY(-50%);
}

.-translate-y-100 {
  transform: translateY(-100%);
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.transition-100 {
  transition: 100ms ease-in-out;
}

.transition-200 {
  transition: 200ms ease-in-out;
}

/* Overflows */

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

/* Visibility */

.opacity-1 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

/* States */
.pointer {
  cursor: pointer;
}

.events-none {
  pointer-events: none;
}

.hover\:bg-accent:hover {
  background-color: var(--color-accent);
}

.hover\:bg-grey-50:hover {
  background-color: var(--grey-50);
}

.hover\:bg-blue-800:hover {
  background-color: var(--blue-800);
}

.hover\:bg-blue-900:hover {
  background-color: var(--blue-900);
}

.hover\:bg-grey-100:hover {
  background-color: var(--grey-100);
}

.hover\:bg-grey-200:hover {
  background-color: var(--grey-200);
}

.hover\:color-white:hover {
  color: var(--white);
}

.hover\:bg-red-900:hover {
  background-color: var(--red-900);
}

.hover\:bg-green-500:hover {
  background-color: var(--green-500);
}

.focus\:border-accent:focus {
  border-color: var(--color-accent);
}

.focus\:ring-accent:focus {
  box-shadow: 0 0 0 2px var(--green-400);
}

.focus\:bg-accent:focus {
  background-color: var(--green-400);
}

.focus\:bg-blue-800:focus {
  background-color: var(--blue-800);
}

.focus\:bg-blue-900:focus {
  background-color: var(--blue-900);
}

.focus\:border-white:focus {
  border-color: var(--white);
}

.focus\:ring-red-900:focus {
  box-shadow: 0 0 0 2px var(--red-900);
}

.focus\:ring-blue-800:focus {
  box-shadow: 0 0 0 2px var(--blue-800);
}

.focus\:ring-blue-900:focus {
  box-shadow: 0 0 0 2px var(--blue-900);
}

.focus\:color-white:focus {
  color: var(--white);
}

.focus\:bg-grey-50:focus {
  background-color: var(--grey-50);
}

.focus\:bg-red-900:focus {
  background-color: var(--red-900);
}

.nowrap {
  white-space: nowrap;
}

/* Components/Custom */

.c-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  width: 100%;
}

.c-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container-affiliates {
  max-width: 1400px;
  width: 90%;
}

.container-md {
  margin: 0 auto;
  max-width: 1200px;
  width: 90%;
}

.container-lg {
  margin: 0 auto;
  max-width: var(--container-lg);
  width: 90%;
}

.container-xl {
  margin: 0 auto;
  max-width: var(--container-xl);
  width: 90%;
}

/* Accessibility  */

.sr-only {
  border-width: 0;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* >= Medium devices */
@media (min-width: 768px) {
  .md\:relative {
    position: relative;
  }

  .md\:none {
    display: none;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:align-center {
    align-items: center;
  }

  .md\:align-start {
    align-items: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:w-1\/5 {
    width: 20%;
  }

  .md\:w-quarter {
    width: 25%;
  }

  .md\:w-third {
    width: 33.333%;
  }

  .md\:w-half {
    width: 50%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:max-w-full {
    max-width: 100%;
  }
  .md\:w-32 {
    width: var(--spacing-32);
  }

  .md\:w-52 {
    width: var(--spacing-52);
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-16 {
    height: var(--spacing-16);
  }

  .md\:h-20 {
    height: var(--spacing-20);
  }

  .md\:my-0 {
    margin-bottom: 0;
    margin-top: 0;
  }

  .md\:my-16 {
    margin-bottom: var(--spacing-16);
    margin-top: var(--spacing-16);
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-8 {
    margin-bottom: var(--spacing-8);
  }

  .md\:mb-10 {
    margin-bottom: var(--spacing-10);
  }

  .md\:mb-16 {
    margin-bottom: var(--spacing-16);
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:ml-12 {
    margin-left: var(--spacing-12);
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:p-8 {
    padding: var(--spacing-8);
  }

  .md\:px-16 {
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
  }

  .md\:px-20 {
    padding-left: var(--spacing-20);
    padding-right: var(--spacing-20);
  }

  .md\:px-24 {
    padding-left: var(--spacing-24);
    padding-right: var(--spacing-24);
  }

  .md\:py-16 {
    padding-bottom: var(--spacing-16);
    padding-top: var(--spacing-16);
  }

  .md\:py-12 {
    padding-bottom: var(--spacing-12);
    padding-top: var(--spacing-12);
  }

  .md\:py-24 {
    padding-bottom: var(--spacing-24);
    padding-top: var(--spacing-24);
  }

  .md\:py-32 {
    padding-bottom: var(--spacing-32);
    padding-top: var(--spacing-32);
  }

  .md\:pt-32 {
    padding-top: var(--spacing-32);
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:size-16 {
    font-size: var(--spacing-16);
  }

  .md\:mr-2 {
    margin-right: var(--spacing-2);
  }

  .md\:mr-4 {
    margin-right: var(--spacing-4);
  }

  .md\:mr-6 {
    margin-right: var(--spacing-6);
  }

  .md\:mr-8 {
    margin-right: var(--spacing-8);
  }

  .md\:mr-12 {
    margin-right: var(--spacing-12);
  }

  .md\:mr-24 {
    margin-right: var(--spacing-24);
  }

  .md\:mx-8 {
    margin-left: var(--spacing-8);
    margin-right: var(--spacing-8);
  }

  .md\:-translate-x-100 {
    transform: translateX(-100%);
  }

  .md\:translate-x-0 {
    transform: translateX(0);
  }

  .md\:radius-left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .md\:radius-right-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:bg-hero {
    background-image: url(/images/hero-banner-image.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 20rem;
    width: 100%;
  }

  .md\:bg-none {
    background: none;
  }

  .md\:bg-white-opacity {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .md\:container-md {
    margin: 0 auto;
    max-width: 1200px;
    width: 90%;
  }
}

@media (min-width: 1024px) {
  .lg\:w-third {
    width: 33.333%;
  }

  .lg\:py-0 {
    padding-bottom: 0;
    padding-top: 0;
  }
}

/* >= XL devices */
@media (min-width: 1280px) {
  .xl\:flex {
    display: flex;
  }

  .xl\:none {
    display: none;
  }

  .xl\:block {
    display: block;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:justify-center {
    justify-content: center;
  }

  .xl\:py-0 {
    padding-bottom: 0;
    padding-top: 0;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:radius-tl-none {
    border-top-left-radius: 0;
  }

  .xl\:w-third {
    width: 33.333%;
  }

  .xl\:w-quarter {
    width: 25%;
  }

  .xl\:w-1\/5 {
    width: 20%;
  }
}

@media print {
  .no-print {
    display: none !important;
  }

  .print\:break-avoid {
    page-break-inside: avoid !important;
  }
}
