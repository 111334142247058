:root {
  /* COLORS */
  --color-border: rgba(0, 0, 0, 0.25);
  --color-button-hover: salmon;
  --color-header: #333;
  --color-link: #88e;
  --color-primary: #6fb2e0;
  --color-accent: #3a8fa2;
  --color-accent-200: #f4f8f8;
  --color-accent-300: #c9eeed;
  --color-accent-400: #ade2e0;
  --white: white;
  --black: #000;
  --grey-50: #f4f5f8;
  --grey-100: #e8eaef;
  --grey-200: #c7ccd8;
  --grey-300: #7a7a7a;
  --green-400: #3a8fa2;
  --green-500: #12748c;
  --green-900: #154745;
  --blue-900: #1b1b46;
  --blue-800: #23527a;
  --blue-700: #1a3564;
  --red-700: #e83c48;
  --red-800: #c72530;
  --red-900: #b21c27;

  /* FONTS */
  --font-body: 'p22-underground', sans-serif;
  --font-header: 'hero-new', serif;

  /* SPACING */
  --spacing-base: 0.25rem;
  --spacing-0-5: calc(var(--spacing-base) / 2);
  --spacing-1: calc(var(--spacing-base) * 1);
  --spacing-2: calc(var(--spacing-base) * 2);
  --spacing-3: calc(var(--spacing-base) * 3);
  --spacing-4: calc(var(--spacing-base) * 4); /* 1rem */
  --spacing-5: calc(var(--spacing-base) * 5);
  --spacing-6: calc(var(--spacing-base) * 6);
  --spacing-7: calc(var(--spacing-base) * 7);
  --spacing-8: calc(var(--spacing-base) * 8); /* 2rem */
  --spacing-9: calc(var(--spacing-base) * 9);
  --spacing-10: calc(var(--spacing-base) * 10);
  --spacing-11: calc(var(--spacing-base) * 11);
  --spacing-12: calc(var(--spacing-base) * 12); /* 3rem */
  --spacing-13: calc(var(--spacing-base) * 13);
  --spacing-14: calc(var(--spacing-base) * 14);
  --spacing-15: calc(var(--spacing-base) * 15);
  --spacing-16: calc(var(--spacing-base) * 16); /* 4rem */
  --spacing-17: calc(var(--spacing-base) * 17);
  --spacing-18: calc(var(--spacing-base) * 18);
  --spacing-19: calc(var(--spacing-base) * 19);
  --spacing-20: calc(var(--spacing-base) * 20); /* 5rem */
  --spacing-24: calc(var(--spacing-base) * 24); /* 6rem */
  --spacing-28: calc(var(--spacing-base) * 28); /* 7rem */
  --spacing-32: calc(var(--spacing-base) * 32); /* 8rem */
  --spacing-40: calc(var(--spacing-base) * 40); /* 10rem */
  --spacing-48: calc(var(--spacing-base) * 48); /* 11rem */
  --spacing-52: calc(var(--spacing-base) * 52); /* 13rem */
  --spacing-60: calc(var(--spacing-base) * 60); /* 15rem */
  --spacing-72: calc(var(--spacing-base) * 72); /* 18rem */
  --spacing-80: calc(var(--spacing-base) * 80); /* 20rem */
  --spacing-88: calc(var(--spacing-base) * 88); /* 22rem */
  --spacing-90: calc(var(--spacing-base) * 90); /* 22.5rem */
  --spacing-96: calc(var(--spacing-base) * 96); /* 24rem */
  --spacing-104: calc(var(--spacing-base) * 104); /* 25rem */
  --spacing-112: calc(var(--spacing-base) * 112); /* 26rem */

  /* TYPOGRAPHY */
  --fs-base: 62.5%;
  --fs-normal: 1.6rem;
  --header-weight: bold;
  --header-lh: 1.5;
  --header-ls: 0.25rem;
  --header-align: center;
  --h1-size: 6rem;
  --h2-size: 4rem;
  --h3-size: 2rem;
  --h4-size: 1.8rem;
  --lh-normal: 1.4;
  --ls-normal: 0.02rem;
  --p-size: 1.6rem;
  --p-lh: 1.4;
  --p-ls: 0.2rem;
  --p-mb: var(--spacing-2);

  /* BORDERS */
  --border: 1px solid var(--color-border);
  --radius: 4px;
  --radius-sm: 2px;
  --radius-md: 6px;
  --radius-lg: 10px;

  /* SHADOWS */
  --box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  /* CONTAINERS */
  --container-lg: 1550px;
  --container-xl: 2454px;
}
